/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    blockquote: "blockquote",
    p: "p",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Ir išvis, kas galėjo pagalvot, kad ryžiai ant kiniškos petelnės\nkaitinami šokinėja? Stačiai gražu."), "\n"), "\n", React.createElement(_components.p, null, "Vakar stebėjau indus gatvėj. Nu, youtubėj šiaip, bet gatvėj. Ir ten jie\nvisai gyvenimą virė. Šiandien spoksau jau kitoniškus azijiečius, kurie\nvisai jau darbą verda. Kažkaip ten išėjo, kad iš per daug taisyklių\ngyvenimo sumažėjo. Mažiau rėkaut reikia, daugiau plastikėlių ant\nrankų užsidėt."), "\n", React.createElement(_components.p, null, "Bet šiaip jau tai visiškai ", React.createElement(_components.em, null, "spakainei"), ". Ir tada bežiūrėdamas aš juos\ngavau susimąstyt apie kaip seniai mąsčiau. Kažkaip suretėjau\npagalvojimuose. Vis daugiau internetų ir žaidimėlių. Vis tie ekranai\nkažkaip spindi. Ir nejučiom susitaškai visuose feed'uose; o tie ant\ngatvės tai visai kitaip feed'ina. Taško kapoja užsiprogramavę ir papūsk\ntu jiem sėdimojon – ", React.createElement(_components.em, null, "gražuoliai"), "."), "\n", React.createElement(_components.p, null, "Ir pagalvoji: ko tau parintis, kai yra pasaulyje žmogus, kuris arbūzą\nramiu žvilgsniu turguj kapoja. Kai randi žmogų, su kelnių galais visai\nvisai prie kulnų, o jie žiemos vidury – sterilūs, o paltas – žemiau\nkelių; ir nosinaitė paupgrade'intai kaktai nusivalyt pati į rankas\nprašosi. O dar ir taksisto gimtadienis tikrai ne kasdien. Žinai,\nempiriniais tyrimais įrodyta, kad europinio galiuko rusiškon skylutėn\nnesukiši. Tai kam ten prakaituot."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
